import {
  ComponentType,
  useContext,
  useEffect,
  useState,
  createContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import queryString from "query-string";
import {
  useNavigate,
  useSearchParams,
  useParams,
  NavigationType,
} from "@remix-run/react";
import { isUndefined, uniq, type DebouncedFunc } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import { UNSAFE_useRouteId, UNSAFE_LocationContext } from "react-router-dom";

import { CombineResult } from "./validate";

const prepareRawDefault = <T extends object>(query: URLSearchParams, options) =>
  queryString.parse(query.toString(), options) as Partial<T>;

const createResettable = (Component: ComponentType) => () => {
  const locationContext = useContext(UNSAFE_LocationContext);
  const [key, setKey] = useState<string | undefined>(
    locationContext.location.key,
  );

  useEffect(() => {
    const { location, navigationType } = locationContext;
    if (navigationType === NavigationType.Push) {
      setKey(location.key);
    }
  }, [locationContext]);

  return <Component key={key} />;
};

type DefaultParams = { [key: string]: string };
type URLUpdates<T, P> = { params?: Partial<P>; search?: Partial<T> };

interface URLOptions<T extends object, V> {
  prepareRaw?(
    query: URLSearchParams,
    stringifyOptions?: queryString.ParseOptions,
  ): Partial<T>;
  prepareValues(rawValues: Partial<T>, validates: CombineResult<T>): T;
  validator?: V;
  options?: queryString.StringifyOptions & queryString.ParseOptions;
  resetKeyOnPush?: boolean;
  defaultValues?: T;
  withoutDefaultValidation?: boolean;
}

export interface URLContextData<T extends object, P extends object> {
  validates: CombineResult<T>;
  params: P;
  search: T;
  setAllParams(to: URLUpdates<T, P>): DebouncedFunc<VoidFunction>;
  setSearchParams(values: Partial<T>): DebouncedFunc<VoidFunction>;
  setSearchParams(callback: (previous: T) => T): DebouncedFunc<VoidFunction>;
}

const URLContext = createContext(null);

export const useURLContext = <
  T extends object,
  P extends object = DefaultParams,
>() => useContext(URLContext) as URLContextData<T, P>;

useURLContext.withTypes = <
  T extends object,
  P extends object = DefaultParams,
>() => useURLContext<T, P>;

export const connectURLParams = <
  T extends object,
  V extends (args: Partial<T>) => CombineResult<T>,
  P extends object = DefaultParams,
>(
  Component: ComponentType,
  {
    prepareValues,
    prepareRaw = prepareRawDefault,
    options,
    validator,
    resetKeyOnPush = true,
    withoutDefaultValidation = false,
    defaultValues = {} as T,
  }: URLOptions<T, V>,
) => {
  let updateStack: URLUpdates<T, P>[] = [];

  const getParsedValues = ({
    search,
    params,
  }: URLUpdates<T, P>): URLUpdates<T, P> => {
    const result: URLUpdates<T, P> = {
      params,
      search: cloneDeep(search),
    };
    const updateItem = updateStack.pop();
    const updateValues = isFunction(updateItem.search)
      ? ({ search: updateItem.search(result.search) } as URLUpdates<T, P>)
      : updateItem;
    for (const [key, value] of Object.entries(updateValues.search || {})) {
      if (value === null || value === undefined) {
        delete result.search[key];
      } else {
        result.search[key] = value as string;
      }
    }

    if (updateValues.params) {
      result.params = { ...result.params, ...updateValues.params };
    }

    if (updateStack.length) {
      return getParsedValues(result);
    }

    return result;
  };

  const Comp = resetKeyOnPush ? createResettable(Component) : Component;
  const defaultKeys = Object.keys(defaultValues);

  const normalizeValue = (defaultValue: unknown, value: unknown) => {
    const isUndefinedValue = isUndefined(value);
    if (defaultValue && Array.isArray(defaultValue)) {
      // eslint-disable-next-line no-nested-ternary
      return !isUndefinedValue
        ? Array.isArray(value)
          ? value
          : [value]
        : defaultValue;
    }

    return isUndefinedValue ? defaultValue : value;
  };

  const getNormalizedRawValues = (search: URLSearchParams) => {
    const parsedValues = prepareRaw(search, options);
    const keys = uniq(Object.keys(parsedValues).concat(defaultKeys));
    const result = {};
    for (const key of keys) {
      result[key] = normalizeValue(defaultValues[key], parsedValues[key]);
    }

    return result;
  };

  return props => {
    const [search] = useSearchParams();
    const navigate = useNavigate();
    const params = useParams() as P;
    const id = UNSAFE_useRouteId();
    const updateFunctionRef = useRef<VoidFunction>(null);
    const urlPattern = useMemo(
      () => id.slice(6).replace("._index", "").replaceAll(".", "/"),
      [id],
    );

    const rawValues = useMemo(() => getNormalizedRawValues(search), [search]);
    const validates = useMemo(
      () => (validator ? validator(rawValues) : { errors: {}, values: {} }),
      [rawValues],
    );
    const values = useMemo(
      () => cloneDeep(prepareValues(rawValues, validates)),
      [rawValues, validates],
    );
    const lastUpdatesRef = useRef<URLUpdates<T, P>>({ search: values, params });

    updateFunctionRef.current = () => {
      if (!updateStack.length) {
        return;
      }

      const result = getParsedValues({
        search: getNormalizedRawValues(new URLSearchParams(location.search)),
        params,
      });
      lastUpdatesRef.current = result;

      navigate(
        {
          search: queryString.stringify(result.search, options),
          pathname: Object.entries(result.params).reduce(
            (pattern, [key, value]) =>
              pattern.replace(`$${key}`, value as string),
            urlPattern,
          ),
        },
        {
          replace: true,
          preventScrollReset: true,
        },
      );
    };

    const updateUrlLazy = useCallback(
      debounce(() => updateFunctionRef.current(), 10),
      [],
    );

    useEffect(() => {
      if (withoutDefaultValidation || isEmpty(validates.errors)) {
        return;
      }

      updateStack.push({ search: values });
      updateUrlLazy();
    }, []);

    useEffect(
      () => () => {
        updateStack = [];
        updateUrlLazy.cancel();
      },
      [],
    );

    return (
      <URLContext.Provider
        value={{
          validates,
          search: values,
          params,
          setSearchParams: search => {
            updateStack.push({ search });
            updateUrlLazy();

            return updateUrlLazy;
          },
          setAllParams: values => {
            updateStack.push(values);
            updateUrlLazy();

            return updateUrlLazy;
          },
        }}
      >
        <Comp {...props} />
      </URLContext.Provider>
    );
  };
};
